<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" align-self="center">
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form_data" lazy-validation v-if="enable">
        <v-row dense>
          <v-col cols="12">
            <p>
              Estimado asegurado {{survey.rha.name}} {{survey.rha.first_surname}} {{survey.rha.second_surname}} a continuación encontrara una
              breve encuesta de servicio con respecto a la atención recibida en
              el hospital {{survey.rha.provider.trade_name}}, le agradeceremos la responda y nos
              haga saber sus comentarios, muchas gracias.
            </p>
          </v-col>
          <v-col cols="12">
            <p>
              1. Recibió información de proceso de atención y valoración médica:
            </p>
            <v-radio-group v-model="data.ask_initial" mandatory row>
              <v-radio label="Sí" value="Sí" />
              <v-radio label="No" value="No" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <p>
              2. Recibió la segunda visita de nuestro personal para entregar la
              Carta Respuesta proporcionada por la compañía de seguros:
            </p>
            <v-radio-group v-model="data.ask_1" mandatory row>
              <v-radio label="Sí" value="Sí" />
              <v-radio label="No" value="No" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <p>
              Nuestro personal le explico las condiciones de la Carta Respuesta
              en cuanto a deducible, coaseguro, honorarios médicos y gastos
              personales
            </p>
            <v-radio-group v-model="data.ask_2" mandatory row>
              <v-radio label="Sí" value="Sí" />
              <v-radio label="No" value="No" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <p>3. La presentación de nuestro personal le pareció:</p>
            <v-radio-group v-model="data.ask_3" mandatory row>
              <v-radio label="Excelente" value="E" />
              <v-radio label="Bueno" value="B" />
              <v-radio label="Regular" value="R" />
              <v-radio label="Malo" value="M" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <p>
              4. Recibió usted apoyo de nuestro personal como parte de sus
              tramites de ingreso y egreso del hospital
            </p>
            <v-radio-group v-model="data.ask_4" mandatory row>
              <v-radio label="Sí" value="Sí" />
              <v-radio label="No" value="No" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <p>
              5. En general, el servicio que recibió de nuestro personal fue:
            </p>
            <v-radio-group v-model="data.ask_5" mandatory row>
              <v-radio label="Excelente" value="E" />
              <v-radio label="Bueno" value="B" />
              <v-radio label="Regular" value="R" />
              <v-radio label="Malo" value="M" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <p>
              6. La imagen, orden y limpieza de nuestro módulo en el hospital la
              considera:
            </p>
            <v-radio-group v-model="data.ask_6" mandatory row>
              <v-radio label="Excelente" value="E" />
              <v-radio label="Bueno" value="B" />
              <v-radio label="Regular" value="R" />
              <v-radio label="Malo" value="M" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <p>7. En general que tan satisfecho quedo con el servicio:</p>
            <v-radio-group v-model="data.ask_7" mandatory row>
              <v-radio label="Excelente" value="E" />
              <v-radio label="Bueno" value="B" />
              <v-radio label="Regular" value="R" />
              <v-radio label="Malo" value="M" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <p>8. Usted recomendaría nuestro servicio</p>
            <v-radio-group v-model="data.ask_8" mandatory row>
              <v-radio label="Sí" value="Sí" />
              <v-radio label="No" value="No" />
              <v-radio label="No sabe" value="No sabe" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <p>9. El servicio que recibió usted del hospital lo considera:</p>
            <v-radio-group v-model="data.ask_9" mandatory row>
              <v-radio label="Excelente" value="E" />
              <v-radio label="Bueno" value="B" />
              <v-radio label="Regular" value="R" />
              <v-radio label="Malo" value="M" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <p>
              10. Los beneficios que recibió de su póliza de GMM, estuvieron
              acorde a lo esperado por usted
            </p>
            <v-radio-group v-model="data.ask_10" mandatory row>
              <v-radio label="Sí" value="Sí" />
              <v-radio label="No" value="No" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-textarea
              rows="2"
              label="Desea agregar algún comentario con respecto al servicio recibido por parte del asesor"
              v-model="data.observation"
              dense
            />
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn
              block
              color="primary"
              :loading="loading"
              @click.prevent="handleSubmit"
            >
              <v-icon left v-text="'mdi-check'" />
              Terminar encuesta
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row v-if="!enable">
        <v-col cols="12" class="text-center">
          <h1 v-text="'La encuesta ya ha sido contestada'" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
//import functions
import { show } from "../../requests/pageRequest";
import { msgConfirm, msgAlert, URL_API } from "../../control";
//import components
import BtnCircle from "../../components/BtnCircle.vue";
import Axios from "axios";

export default {
  components: {
    BtnCircle,
  },
  data() {
    return {
      //route name and page title
      page_route: "public/survey",
      id: atob(this.$route.params.id),
      loading: false,
      data: {
        ask_initial: "",
        ask_1: "",
        ask_2: "",
        ask_3: "",
        ask_4: "",
        ask_5: "",
        ask_6: "",
        ask_7: "",
        ask_8: "",
        ask_9: "",
        ask_10: "",
        observation: "",
      },
      enable: true,
      survey:{rha:{provider:{}}}
    };
  },
  methods: {
    handleSubmit() {
      //validate form data
      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma el envío de esta encuesta?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;
              Axios.post(
                URL_API + "/encuesta/" + this.id + "/app",
                this.data,
                {
                  "Content-Type": "application/json",
                  "X-Requested-With": "XMLHttpRequest",
                }
              ).then((response) => {
                this.loading = false;
                this.enable = false;
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                );
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    show("survey", null, this.id).then((response) => {
      this.survey = response;

      if (this.survey) {
        if (
          this.survey.survey_stadistic_id === 1 ||
          this.survey.survey_stadistic_id === 6
        )
          this.enable = false;
      }

      this.survey_loading = false;
    });
  },
};
</script>